import "./styles/index.scss";
import "smoothscroll-anchor-polyfill";

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  VisitorIdentification,
  withSitecoreContext,
} from "@sitecore-jss/sitecore-jss-react";
import smoothscrollPolyfill from "smoothscroll-polyfill";
import MetaData from "./utils/MetaData";
import GtmScripts from "./utils/GtmScripts";
import DataLayerInit from "./utils/DataLayerInit";
import Placeholder from "./core/Components/Placeholder";
import { withHeaderContext } from "./HeaderContext";

/*
APP LAYOUT
This is where the app's HTML structure and root placeholders should be defined.

All routes share this root layout by default (this could be customized in RouteHandler),
but components added to inner placeholders are route-specific.
*/

export const ThemeSelector = ({ onStylesLoaded, shouldLoadCDS, theme }) => {
  // load themes
  useEffect(() => {
    const stylePromiseArray = [];

    if (shouldLoadCDS) {
      stylePromiseArray.push(
        import(
          "../node_modules/@cochlear-design-system/foundation/dist/index.css"
        ),
      );
    }

    switch (theme) {
      case "cochlear-foundation": {
        stylePromiseArray.push(
          import(
            "./styles/themes/cochlear-foundation/cochlear-foundation.scss"
          ),
        );
        break;
      }

      case "cochlear-rebrand": {
        stylePromiseArray.push(
          import("./styles/themes/cochlear-rebrand/cochlear-rebrand.scss"),
        );
        break;
      }

      case "standard-of-care": {
        stylePromiseArray.push(
          import("./styles/themes/standard-of-care/standard-of-care.scss"),
        );
        break;
      }
    }

    if (stylePromiseArray.length > 0) {
      Promise.all(stylePromiseArray).then(onStylesLoaded);
    }
  }, []);

  return null;
};

const Layout = ({ headerContext, route, sitecoreContext }) => {
  const { fields } = route;

  const [isLoadingTheme, setIsLoadingTheme] = useState(
    !!(fields.useRebrandStyling?.value || fields.theme?.value),
  );

  useEffect(() => {
    // Only continue if polyfills are actually needed
    if (!("scrollBehavior" in document.documentElement.style)) {
      smoothscrollPolyfill.polyfill();
    }
  }, []);

  const classNameNoScroll =
    !headerContext.pageScrollable || headerContext.showMobileMenu
      ? "no-scroll"
      : "";

  const webDir =
    sitecoreContext.metadata.webDirection === "Right to left" ? "rtl" : "ltr";

  const parentClassNames = `l-layout l-one-column cf ${route.templateName}`;

  return (
    <>
      <MetaData />
      <DataLayerInit route={route} context={sitecoreContext} />
      <GtmScripts
        gtmIdGlobal={sitecoreContext.metadata.gtmContainerGlobal}
        gtmIdRegional={sitecoreContext.metadata.gtmContainerRegion}
      />
      <VisitorIdentification />
      <ThemeSelector
        onStylesLoaded={() => setIsLoadingTheme(false)}
        shouldLoadCDS={fields.useRebrandStyling?.value}
        theme={fields.theme?.value}
      />

      <div
        dir={webDir}
        data-template={route.templateName}
        className={`main-content${
          fields.isProfessional.value ? " professionals-page" : ""
        } ${classNameNoScroll} ${
          fields.useRebrandStyling?.value ? "" : parentClassNames
        }`}
        // theme css loads from separate chunk due to dynamic import, hide page until ready
        style={isLoadingTheme ? { visibility: "hidden" } : undefined}
      >
        <main
          id="main"
          tabIndex="-1"
          // element with theme class name must be a child of the element with dir attribute
          className={`${fields.useRebrandStyling?.value ? "" : "l-main "}${
            fields.theme?.value || ""
          }`}
        >
          <Placeholder name="global-header" rendering={route} />
          <article
            id="article"
            className={`article ${
              fields.useRebrandStyling?.value ? "" : "l-article"
            } ${headerContext.showMobileMenu ? "is-hidden" : ""} `}
          >
            <Placeholder name="header" rendering={route} />

            {(sitecoreContext.pageEditing ||
              (!!route.placeholders.main &&
                !!route.placeholders.main.length)) && (
              <div
                className={`${
                  fields.useRebrandStyling?.value ? "" : "l-padding"
                }`}
              >
                <div
                  className={`${
                    fields.useRebrandStyling?.value ? "" : "l-content-column"
                  }`}
                >
                  <Placeholder name="main" rendering={route} />
                </div>
              </div>
            )}

            <Placeholder name="footer" rendering={route} />
          </article>
          <Placeholder name="global-footer" rendering={route} />
        </main>
      </div>
    </>
  );
};

export default withSitecoreContext()(withRouter(withHeaderContext(Layout)));
